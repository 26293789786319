import { CPT } from '../patient-data';

export interface ICD {
  name?: string;
  code?: string;
}

export interface Medication {
  basicInformation: {
    // The base name of the medication, excluding strength, quantity, and other details
    medicationName: string;
    // National Drug Code - unique identifier for this specific medication formulation
    ndcCode?: string;
  };
  dosage: {
    strength?: {
      // Amount or range of the active ingredient (e.g., 0.1, 1, 2, 10, 10 - 20)
      value: string;
      // Unit of measurement for the strength (e.g., mg, mL, mg/mL, %, units/g, mcg, mEq)
      unit: string;
    };
    quantity?: {
      // Total amount prescribed
      value?: number;
      // Unit for the prescribed amount (e.g., tablets, capsules, mL)
      unit?: string;
    };
  };

  /***
   *
   * Coming soon 🔮
   * If you implement those fields in your adapter, please add them under FF and get Vim-OS approval before opening in prod
   *
   * TODO: make administration/timeline/documentation sub-entities required, with sub-fields optional
   *
   ***/
  administration?: {
    // How the medication should be administered (e.g., oral, nasal, transdermal)
    route?: string;
    // How often to take the medication (e.g., daily, twice a day, as needed)
    frequency?: string;
    // Special instructions for taking the medication (e.g., with food/milk, before/after meals)
    take?: string;
  };
  // When the medication should be taken
  timeline?: {
    // When the medication regimen begins (YYYY-MM-DD)
    startDate?: string;
    // When the medication regimen ends (YYYY-MM-DD)
    endDate?: string;
    // Number of days between start and end dates
    duration?: number;
    // Current state of the prescription (e.g., active, inactive, historical)
    status?: string;
  };
  // Additional clinical context and notes
  documentation?: {
    // Healthcare provider who prescribed or recorded the medication
    reportedBy?: string;
    // Additional instructions or comments about the medication
    notes?: string;
    // Original free-text description if medication was entered as unstructured data in EHR
    unstructured?: string;
  };
}

export enum OrderType {
  LAB = 'LAB',
  DI = 'DI',
  PROCEDURE = 'PROCEDURE',
  RX = 'RX',
}

export interface Order {
  id?: string;
  type?: OrderType;
  icd: ICD[];
  cpts: CPT[];
  encounterId?: string;
  createdDate?: string; // YYYY-MM-DD
  medications?: Medication[];
}
