import { v4 as uuid } from 'uuid';
import { encounterIdA } from '../encounters';
import { Order, OrderType } from '@getvim-os/types';

export const orderG: Order = {
  id: uuid(),
  type: OrderType.DI,
  icd: [],
  cpts: [
    {
      name: 'Adjacent tissue transfer or rearrangement, trunk; defect 10 sq cm or less',
      code: '14000',
    },
  ],
  encounterId: encounterIdA,
  createdDate: '2000-01-01',
};
