import React, { FC, useEffect, useState, MouseEvent, useRef } from 'react';
import { MenuItem, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { ConnectorsProps, connectorsTypeList, ICONS_MAP } from '../../../types';
import { getDisplayTime } from '../../../services';
import { connectorsMocks, executeConnectorFunction } from '../../mocks/connectors';
import { ButtonLoader, ButtonIcon, JsonEditor, Selector } from '../common';

const SELECT_FUNCTION: string = 'Select function';

export const Connectors: FC<ConnectorsProps> = ({
  connectorsResponse,
  windowHandlesTrack,
  onClearAllHighlighterEvents,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectorsType, setConnectorsType] = useState<string>(connectorsTypeList[0].key);
  const [connectorsFunctionsList, setConnectorsFunctionsList] = useState<string[]>([]);
  const [connectorsFunction, setConnectorsFunction] = useState<string>(SELECT_FUNCTION);
  const [payload, setPayload] = useState<any>('');
  const [connectorsLocalResponse, setConnectorsLocalResponse] = useState<any>(undefined);
  const [shouldAttachListener, setShouldAttachListener] = useState<boolean>(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const addAudioBlob = useAudioBlobs(audioRef);
  const [audioChunkCount, setAudioChunkCount] = useState(0);
  const [audioControlEvents, setAudioControlEvents] = useState<any[]>([]);

  useEffect((): void => {
    setConnectorsLocalResponse(connectorsResponse);
  }, [connectorsResponse]);

  const clearEditors = (): void => {
    setPayload('');
    setConnectorsLocalResponse('');
  };

  const handleConnectorsTypeSelectChange = (e: SelectChangeEvent): void => {
    clearEditors();
    const defaultSelect: string = connectorsTypeList[0].key;
    if (e.target.value === defaultSelect) {
      setConnectorsType(defaultSelect);
      return;
    }
    setConnectorsFunction[SELECT_FUNCTION];
    setConnectorsType(e.target.value);
    let sortedFunctions: string[] = Object.keys(connectorsMocks[e.target.value]).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
    );
    sortedFunctions = [SELECT_FUNCTION, ...sortedFunctions];
    setConnectorsFunctionsList(sortedFunctions);
    setConnectorsFunction[sortedFunctions[0]];
  };

  const handleConnectorsFunctionSelectChange = (e: SelectChangeEvent): void => {
    clearEditors();
    if (e.target.value === SELECT_FUNCTION) {
      setConnectorsFunction(SELECT_FUNCTION);
      return;
    }
    setConnectorsFunction(e.target.value);
    setPayload(connectorsMocks[connectorsType][e.target.value]);
  };

  const handleJsonEditorChange = (newValue: any): void => {
    setPayload(newValue);
  };

  const handleRunClick = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.stopPropagation();
    setIsLoading(true);
    setConnectorsLocalResponse('');

    if (shouldAttachListener) {
      payload.handler = (receivedEvent) => {
        if (receivedEvent?.type === 'audio-chunk') {
          setAudioChunkCount((count) => count + 1);
          addAudioBlob(receivedEvent.data);
        } else {
          setAudioControlEvents((prev) => [...prev, receivedEvent]);
        }
      };
    }
    const result: any = await executeConnectorFunction({
      type: connectorsType,
      funcName: connectorsFunction,
      payload,
    });
    let resultPayload = '';
    try {
      resultPayload = JSON.parse(result);
    } catch {
      resultPayload = result;
    }
    setConnectorsLocalResponse(resultPayload);
    setIsLoading(false);
  };

  return (
    <>
      <div className="connectors">
        <div className="text-divider">Connectors</div>
        <div className="connectors-container">
          <div className="connectors-dropdown">
            <div className="connectors-type">
              <FormControl size="small">
                <div className="connectors-type-select">
                  <Selector
                    id="connectors-type-selector"
                    labelId="connectors-type-selector-label"
                    value={connectorsType}
                    onChange={handleConnectorsTypeSelectChange}
                  >
                    {connectorsTypeList.map(({ key, value }) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Selector>
                </div>
              </FormControl>
            </div>
            <div className="connectors-function">
              {connectorsType !== connectorsTypeList[0].key && (
                <FormControl size="small">
                  <div className="connectors-function-select">
                    <Selector
                      id="connectors-function-selector"
                      labelId="connectors-function-selector-label"
                      value={connectorsFunction}
                      onChange={handleConnectorsFunctionSelectChange}
                      disabled={connectorsType === connectorsTypeList[0].key}
                    >
                      {connectorsFunctionsList.map((key: string) => (
                        <MenuItem key={key} value={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </Selector>
                  </div>
                </FormControl>
              )}
            </div>
          </div>
          <div>
            <input
              type="checkbox"
              checked={shouldAttachListener}
              onChange={(event) => setShouldAttachListener(event.target.checked)}
            />
            <span>Attach Listener</span>
          </div>
          <div className="connectors-payload">
            <JsonEditor
              value={payload}
              height={10}
              ignoreInvalidJsonOnChange
              onChange={handleJsonEditorChange}
            />
          </div>
          <div className="connectors-submit">
            <ButtonLoader
              id="connectors-run"
              className="button connectors-button"
              isLoading={isLoading}
              onClick={handleRunClick}
            />
          </div>
          <div className="connectors-response">
            <JsonEditor value={connectorsLocalResponse} height={10} ignoreInvalidJsonOnChange />
          </div>
          <div>
            <div>Audio chunks received: {audioChunkCount}</div>
            <audio ref={audioRef} controls />
            <div>Control Events</div>
            {audioControlEvents.map((event, index) => (
              <div key={index}>{JSON.stringify(event)}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="highlighter">
        <div className="text-divider">Highlighter</div>
        <div className="highlighter-container">
          <div className="panel">
            <div className="button-container">
              <ButtonIcon
                id="clear-all-controls"
                className="button mini"
                onClick={onClearAllHighlighterEvents}
                disabled={false}
                icon={ICONS_MAP['delete']}
              />
            </div>
            <div className="note">
              <div className="item parallels">
                <div className="icon"></div>
                <div className="tip">Parallels Desktop: Ctrl + Func + F7</div>
              </div>
              <div className="item ms">
                <div className="icon"></div>
                <div className="tip">Microsoft Remote Desktop: Ctrl + F7</div>
              </div>
            </div>
          </div>
          <div className="text-divider">Control Ids</div>
          {windowHandlesTrack.map(({ id, data, receivedDate }) => (
            <div key={id} className="control-wrap">
              <div className="adapter-control-title">
                <div className="control">
                  <div className="control-box id">control_id: {data}</div>
                  <div className="control-box">
                    {getDisplayTime(new Date(receivedDate), true).displayTime}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const useAudioBlobs = (ref: React.RefObject<HTMLAudioElement>) => {
  const mediaSourceRef = useRef<MediaSource | null>(null);
  const sourceBufferRef = useRef<SourceBuffer | null>(null);
  const blobQueueRef = useRef<Blob[]>([]);
  const mime = 'audio/webm; codecs=opus';

  useEffect(() => {
    if (!(ref.current && !mediaSourceRef.current)) {
      return;
    }

    const mediaSource = new MediaSource();
    mediaSourceRef.current = mediaSource;
    ref.current.src = URL.createObjectURL(mediaSource);

    mediaSource.addEventListener('sourceopen', () => {
      if (!MediaSource.isTypeSupported(mime)) {
        console.error('MIME type not supported:', mime);
        return;
      }

      const sourceBuffer = mediaSource.addSourceBuffer(mime);
      sourceBuffer.mode = 'sequence';
      sourceBufferRef.current = sourceBuffer;

      sourceBuffer.addEventListener('updateend', async () => {
        if (blobQueueRef.current.length > 0 && !sourceBuffer.updating) {
          const nextBlob = blobQueueRef.current.shift();
          if (!nextBlob) {
            return;
          }
          const buffer = await nextBlob.arrayBuffer();
          try {
            sourceBuffer.appendBuffer(buffer);
          } catch (error) {
            console.error('appendBuffer error:', error);
            blobQueueRef.current.push(nextBlob);
          }
        }
      });
    });
  }, [ref]);

  return async (blob: Blob) => {
    const sourceBuffer = sourceBufferRef.current;
    if (!sourceBuffer || sourceBuffer.updating) {
      blobQueueRef.current.push(blob);
      return;
    }

    const buffer = await blob.arrayBuffer();
    try {
      sourceBuffer.appendBuffer(buffer);
    } catch (error) {
      console.error('appendBuffer error:', error);
      blobQueueRef.current.push(blob);
    }
  };
};

Connectors.displayName = 'Connectors';
