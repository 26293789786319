import gql from 'graphql-tag';
import { logger } from '@getvim/vim-connect-logger';
import { retry } from '@vim/promise';
import { gqlClient } from '../../gql-client';

const getUserSecretsLogger = logger.scope('Get user secrets queries');

const getUserSecretsQuery = gql`
  query UserSecrets {
    getUserSecrets {
      sqlSecrets {
        database
        server
        user
        password
      }
    }
  }
`;

export interface UserSecrets {
  sqlSecrets?: {
    database?: string;
    server?: string;
    user?: string;
    password?: string;
  };
}

interface GetUserSecretsQueryResult {
  getUserSecrets: UserSecrets;
}

export const getUserSecrets = async (): Promise<UserSecrets | undefined> => {
  try {
    const result = await retry(
      async () => {
        return await gqlClient.query<GetUserSecretsQueryResult>({
          query: getUserSecretsQuery,
          fetchPolicy: 'no-cache',
        });
      },
      {
        retries: 3,
        minTimeout: 200,
      },
    );
    return result?.data?.getUserSecrets;
  } catch (error) {
    getUserSecretsLogger.error('error while receiving user secrets', { error });
    return undefined;
  }
};
