import { Insurer } from '@getvim-os/standard';
import { Standard } from '@getvim/vim-connect';

const demographics: Standard.Entities.PatientDemographics = {
  firstName: 'John',
  lastName: 'Doe',
  dateOfBirth: '1985-04-12',
  gender: Standard.Entities.Gender.male,
};

const insurance: Standard.Entities.PatientInsurance = {
  ehrInsurance: 'maxhealth',
  insurer: Insurer.UHC,
};

const patientId: string = 'test_maxhealth_1738574261';

export const johnDoe: Standard.Events.PatientInContextPayload = {
  patientId,
  demographics,
  insurance,
};
