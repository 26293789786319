import { v4 as uuid } from 'uuid';
import { encounterIdA } from '../encounters';
import { Order, OrderType } from '@getvim-os/types';

export const orderA: Order = {
  id: uuid(),
  type: OrderType.RX,
  icd: [],
  cpts: [],
  encounterId: encounterIdA,
  createdDate: '2000-01-01',
  medications: [
    {
      basicInformation: {
        medicationName: 'Medication A',
      },
      dosage: {
        strength: {
          value: '1',
          unit: 'tablet',
        },
      },
    },
  ],
};
