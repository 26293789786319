import type { EditableReferral } from '@getvim-adapter/api';

export const editableReferral: EditableReferral = {
  basicInformation: {
    specialty: {
      isEditable: true,
      reason: 'Mock Adapter Data',
    },
    startDate: {
      isEditable: true,
      reason: 'Mock Adapter Data',
    },
    endDate: {
      isEditable: false,
      reason: 'Mock Adapter Data',
    },
    priority: {
      isEditable: true,
      reason: 'Mock Adapter Data',
    },
    numberOfVisits: {
      isEditable: true,
      reason: 'Mock Adapter Data',
    },
    authCode: {
      isEditable: true,
      reason: 'Mock Adapter Data',
    },
    reasons: {
      isEditable: false,
      reason: 'Mock Adapter Data',
    },
    notes: {
      isEditable: false,
      reason: 'Mock Adapter Data',
    },
  },
  conditions: {
    diagnosis: {
      isEditable: true,
      reason: 'Mock Adapter Data - Not Implemented',
    },
  },
  procedureCodes: {
    cpts: {
      isEditable: true,
      reason: 'Mock Adapter Data',
    },
  },
  targetProvider: {
    isEditable: true,
    reason: 'Mock Adapter Data',
  },
  status: {
    isEditable: false,
    reason: 'Mock Adapter Data - Not Implemented',
  },
  identifiers: {
    ehrReferralId: {
      isEditable: false,
      reason: 'Mock Adapter Data - Not Implemented',
    },
    vimReferralId: {
      isEditable: false,
      reason: 'Mock Adapter Data - Not Implemented',
    },
  },
};
